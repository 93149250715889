import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {
  const main = [
    { name: 'Articles', href: '/articles' },
    { name: 'Talks', href: '/talks' },
    { name: 'Podcast', href: '/podcast' },
    { name: 'Mon profil', href: '/profile' },
    { name: 'Uses', href: '/uses' },
  ]

  const social = [
    {
      name: 'GitHub',
      href: 'https://github.com/guillaumebriday',
      icon: 'github',
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/guillaumebriday',
      icon: 'x-twitter',
    },
    {
      name: 'Instagram',
      href: 'https://instagram.com/guillaumebriday',
      icon: 'instagram',
    },
    {
      name: 'Twitch',
      href: 'https://www.twitch.tv/guillaumebriday',
      icon: 'twitch',
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/@GuillaumeBriday',
      icon: 'youtube',
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/in/guillaumebriday',
      icon: 'linkedin',
    },
    {
      name: 'Sponsor',
      href: 'https://github.com/sponsors/guillaumebriday',
      icon: 'mug-hot',
      iconPrefix: 'fas',
    },
  ]

  return (
    <>
      <footer className="bg-white">
        <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
          <nav
            className="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            {main.map((item) => (
              <div key={item.name} className="px-5 py-2">
                <a
                  href={item.href}
                  className="text-base text-gray-500 hover:text-gray-900"
                >
                  {item.name}
                </a>
              </div>
            ))}
          </nav>

          <div className="mt-8 flex justify-center space-x-6">
            {social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <FontAwesomeIcon icon={[item.iconPrefix || 'fab', item.icon]} />
              </a>
            ))}
          </div>

          <p className="mt-8 text-center text-base text-gray-400">
            &copy; {new Date().getFullYear()} Guillaume Briday. All rights
            reserved.
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer
